body.users.edit {
  div.avatar-area {
    div.image-uploader {
      margin:0 auto !important;
      div.dnd-zone {
        div.image-zone {
          img.image {
            border-radius: 50%;
          }
        }
      }
    }
  }

  #from-twitter {
    margin-top:32px;
  }
  #twitter-name-hidden {
    display:none;
  }
}
